import { EnterElement, ScaleLinear, Selection, symbolCircle, symbolCross, symbolDiamond, symbolSquare, symbolStar, symbolTriangle, symbolWye, SymbolType } from "d3";
import { D3OneToOneRenderable } from "../../../D3/D3OneToOneRenderable";
import { D3ScatterPlotPointConfig } from "../../../../Types/ScatterPlot";
import { ReactCallbacks } from "../../../../Types/ReactCallbacks";
import { seriesCanvasPoint } from "d3fc";

export type D3ScatterPlotCanvasConfig = {
    data: D3ScatterPlotPointConfig[]
    yScale: ScaleLinear<any, any, any>
    xScale: ScaleLinear<any, any, any>
}

export const ScatterPlotPointShapes = ['circle', 'cross', 'diamond', 'square', 'star', 'triangle', 'wye']

export class D3ScatterPlotCanvas extends D3OneToOneRenderable<SVGGElement, SVGForeignObjectElement, D3ScatterPlotCanvasConfig>{
    private context?: CanvasRenderingContext2D
    private symbols: { [key: string]: SymbolType } = {
        'circle': symbolCircle,
        'cross': symbolCross,
        'diamond': symbolDiamond,
        'square': symbolSquare,
        'star': symbolStar,
        'triangle': symbolTriangle,
        'wye': symbolWye
    }
    private canvasPointSeries = seriesCanvasPoint()
        .crossValue((d: { x: number }) => d.x)
        .mainValue((d: { y: number }) => d.y)
        .size((d: { size: number; }) => d.size ** 2)
        .type((d: { shape: string | number; }) => this.symbols[d.shape])
        .decorate((context: { fillStyle: any; }, d: { color: any; }) => {
            context.fillStyle = d.color
        })

    constructor(root: SVGGElement, config: D3ScatterPlotCanvasConfig, reactCallbacks: ReactCallbacks<any>) {
        super(root, config, 'D3-scatter-plot-canvas', reactCallbacks)

        this.render()
    }

    public updateData(newData: D3ScatterPlotPointConfig[]): void {
        this.config.data = newData
    }

    protected enter(newElements: Selection<EnterElement, D3ScatterPlotCanvasConfig, any, any>): Selection<SVGForeignObjectElement, D3ScatterPlotCanvasConfig, SVGGElement, any> {
        const foreignObject = newElements.append("foreignObject").attr("class", this.className)
        const canvas = foreignObject.append("xhtml:canvas") as Selection<HTMLCanvasElement, any, any, any>

        const width = this.config.xScale?.range()[1]
        const height = this.config.yScale.range()[0]

        foreignObject.attr("width", width).attr("height", height)
        canvas.attr("width", width).attr("height", height)

        const canvasNode = canvas.node()

        if (canvasNode != null) {
            this.context = canvasNode.getContext("2d") ?? undefined
        }

        return foreignObject
    }

    protected update(updatedElements: Selection<SVGForeignObjectElement, D3ScatterPlotCanvasConfig, any, any>): Selection<SVGForeignObjectElement, D3ScatterPlotCanvasConfig, SVGGElement, any> {
        const updatedCanvas = updatedElements.select("canvas")

        const width = this.config.xScale?.range()[1]
        const height = this.config.yScale.range()[0]

        updatedElements.attr("width", width).attr("height", height)
        updatedCanvas.attr("width", width).attr("height", height)

        this.renderPoints()

        return updatedElements
    }

    public renderPoints() {
        if (!this.context || !this.config.data) return

        const width = this.config.xScale.range()[1]
        const height = this.config.yScale.range()[0]
        this.context.clearRect(0, 0, width, height)

        this.canvasPointSeries
            .context(this.context)
            .xScale(this.config.xScale)
            .yScale(this.config.yScale)

        this.canvasPointSeries(this.config.data)
    }
}