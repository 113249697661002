import * as XLSX from 'xlsx'

const MedicationsFilterFunction = async (_, file) => {
	const readData = (file) => {
		if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
			return new Promise((resolve, reject) => {
				let reader = new FileReader();
				reader.onload = function (e) {
					const data = new Uint8Array(e.target.result);
					const workbook = XLSX.read(data, { type: 'array' });
					const sheetName = workbook.SheetNames[0]; // Assuming you want to read the first sheet
					const sheet = workbook.Sheets[sheetName];
					const jsonData = XLSX.utils.sheet_to_json(sheet);

					resolve(jsonData.length - 3);
				};

				reader.onerror = function () {
					reject('Unable to read ' + file.name);
				};

				reader.readAsArrayBuffer(file);
			});
		} else {
			return new Promise((resolve, reject) => {
				let reader = new FileReader();
				reader.readAsText(file);
				reader.onload = function () {
					let data = reader.result;
					let rows = data.split('\n');
					let headers = rows[0].split(',');
					let nMedications = headers.length - 1;
					resolve(nMedications);
				};
				reader.onerror = function () {
					reject('Unable to read ' + file.name);
				};
			});
		}

	};

	try {
		const nMedications = await readData(file);

		let filteredInputData = {
			nMedications: nMedications,
			allFiles: file
		};

		return filteredInputData;
	} catch (error) {
		console.log(error);
		return null; // or handle the error in an appropriate way
	}
};

export default MedicationsFilterFunction;
