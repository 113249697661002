import { D3DragEvent, DragBehavior, EnterElement, ScaleLinear, Selection, axisLeft, drag } from "d3";
import { ScatterPlotReactCallbacks } from "../../../../Types/ReactCallbacks";
import { D3OneToOneRenderable } from "../../../D3/D3OneToOneRenderable";
import { D3DragOverlay } from "../../../D3/D3DragOverlay";
import { AxisConfig } from "../../../../Types/ScatterPlot";

export type ScatterPlotYAxisConfig = {
    scale: ScaleLinear<any, any, any>
    stepSize: number
    yAxisConfig: AxisConfig
    onDrag: () => void
    onDoubleClick: () => void
}

export class D3ScatterPlotYAxis extends D3OneToOneRenderable<SVGGElement, SVGGElement, ScatterPlotYAxisConfig, ScatterPlotReactCallbacks> {
    private axisClassName: string = "d3-scatter-plot-y-axis"
    private dragOverlay?: D3DragOverlay
    private width: number = 30
    private verticalExtension: number = 10
    private dragBehavior: DragBehavior<any, any, any>

    constructor(root: SVGGElement, config: ScatterPlotYAxisConfig, reactCallbacks: ScatterPlotReactCallbacks) {
        super(root, config, "d3-scatter-plot-y-axis-container", reactCallbacks)

        this.dragBehavior = this.dragBehavior = drag()
            .on("drag", this.onDrag)
            .on("end", this.onDragEnd)

        this.render()
    }

    protected enter(newElements: Selection<EnterElement, ScatterPlotYAxisConfig, any, any>): Selection<SVGGElement, ScatterPlotYAxisConfig, SVGGElement, any> {
        // Create the Axis Group
        const axisGroup = newElements
            .append("g")
            .attr("class", this.className)

        axisGroup.append("g")
            .attr("class", this.axisClassName)
            .style("user-select", "none") // disables highlighting the ticks

        axisGroup.each((config, i, nodes) => this.createChildren(config, i, nodes))

        return axisGroup
    }

    protected update(updatedElements: Selection<SVGGElement, ScatterPlotYAxisConfig, any, any>): Selection<SVGGElement, ScatterPlotYAxisConfig, SVGGElement, any> {
        const axisGroup = updatedElements

        const d3Axis = axisGroup.select("." + this.axisClassName) as Selection<SVGGElement, any, any, any>

        d3Axis.call(axisLeft(this.config.scale))

        return updatedElements
    }

    protected updateDerivedState(): void {
        this.updateChildren()
    }

    protected createChildren(config: ScatterPlotYAxisConfig, index: number, nodes: ArrayLike<SVGGElement>): void {
        const root = nodes[index]
        const height = config.scale.range()[0]
        const boundingBox = { x: -this.width, y: -this.verticalExtension, width: this.width, height: height + 2 * this.verticalExtension }
        this.dragOverlay = new D3DragOverlay(root, { boundingBox, dragBehavior: this.dragBehavior }, this.reactCallbacks, { onDoubleClick: this.config.onDoubleClick })
    }

    protected updateChildren = () => {
        const height = this.config.scale.range()[0]
        const boundingBox = { x: -this.width, y: -this.verticalExtension, width: this.width, height: height + 2 * this.verticalExtension }
        this.dragOverlay?.updateConfig({ boundingBox, dragBehavior: this.dragBehavior })
    }

    protected renderChildren = () => {
        this.dragOverlay?.render()
    }

    private onDrag = (dragEvent: D3DragEvent<SVGRectElement, ScatterPlotYAxisConfig, any>) => {
        const min = this.config.scale.domain()[0]
        const max = this.config.scale.domain()[1]
        const height = this.config.scale.range()[0] - this.config.scale.range()[1]

        const domainRange = max - min
        const sensitivity = domainRange / height
        let { dx, dy } = dragEvent

        const pointerY = dragEvent.y + dy
        const zoomScaling = pointerY / height

        const newMinY = min + dy * sensitivity + 0.5 * (dx * sensitivity) * (1 - zoomScaling)
        const newMaxY = max + dy * sensitivity - 0.5 * (dx * sensitivity) * zoomScaling

        this.config.scale.domain([newMinY, newMaxY])

        requestAnimationFrame(() => {
            if (this.config.onDrag) {
                this.config.onDrag()
            }

            this.render()
        })
    }

    private onDragEnd = () => {
        const min = this.config.scale.domain()[0]
        const max = this.config.scale.domain()[1]

        this.reactCallbacks.setRootConfig(previous => {
            const yAxisConfig = { ...previous.yAxisConfig }
            yAxisConfig.maxValue = max
            yAxisConfig.minValue = min
            return { ...previous, yAxisConfig: yAxisConfig }
        })
    }
}