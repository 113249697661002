import { useRecoilValue, useSetRecoilState } from "recoil"
import { annotationGroupsAtom, annotationsAtom, activeAnnotationSessionAtom } from "../Pages/Data/Visualize/DataReview/Atoms/Annotations"
import { useEndpointProvider } from "../Providers/EndpointProvider"
import { Annotation } from "../Managers/VisualizationManager/Variables/Annotations"
import { useAuthProvider } from "../Providers/AuthProvider"
import { AnnotationGroup, AnnotationJSON } from "../Pages/Data/Visualize/DataReview/Types/Annotations"
import { currentPatientFileInfoAtom } from "../Pages/Data/Visualize/DataReview/Atoms/PatientFile"
import { useCallback } from "react"
import { DataSource } from "../Pages/Data/Visualize/DataReview/Types/DataSource"
import { useBackendLinksProvider } from "../Providers/BackendLinksProvider"

export const useAnnotationService = () => {
	const endpointProvider = useEndpointProvider()
	const currentAnnotationSession = useRecoilValue(activeAnnotationSessionAtom)
	const patientInfo = useRecoilValue(currentPatientFileInfoAtom)

	const authProvider = useAuthProvider()

	const setAnnotations = useSetRecoilState(annotationsAtom)
	const setAnnotationGroups = useSetRecoilState(annotationGroupsAtom)

	const { LINKS } = useBackendLinksProvider()

	async function createAnnotation(annotation: Annotation): Promise<Annotation> {
		if (!currentAnnotationSession) {
			throw new Error("Annotation Session must be selected before creating an annotation.")
		}

		const newAnnotation = Annotation.fromConfig(annotation)
		newAnnotation.group_id = currentAnnotationSession.id.toString()
		newAnnotation.loaded = true
		newAnnotation.user_id = authProvider.currentUser.id

		await endpointProvider
			.post(LINKS.DATA.PROFILING.ADD_ANNOTATION, {
				annotation_group_id: currentAnnotationSession.id,
				annotation: newAnnotation.toJSON()
			})
			.then(storedAnnotationJSON => {
				const json = JSON.parse(storedAnnotationJSON)
				newAnnotation.id = parseInt(json.id)
				setAnnotations(previous => [...previous, newAnnotation])
				setAnnotationGroups(previous =>
					previous.map(group => {
						if (group.id === currentAnnotationSession.id) {
							return { ...group, nannotations: group.nannotations + 1 }
						}
						return group
					})
				)
			})

		return newAnnotation
	}

	async function modifyAnnotation(annotation: Annotation, data: any): Promise<void> {
		if (!currentAnnotationSession) {
			throw new Error("Annotation Session must be selected before creating an annotation.")
		}

		const modifiedAnnotation = new Annotation()
		Object.assign(modifiedAnnotation, data)

		const body = {
			data_object_id: patientInfo.dataSourceMap.get(DataSource.CURRENT_PATIENT),
			annotation: modifiedAnnotation.toJSON(),
			annotation_group_id: annotation.group_id ?? currentAnnotationSession.id, // This is weird. Annotations should have group ids.
		}

		return endpointProvider
			.post(LINKS.DATA.PROFILING.MODIFY_ANNOTATION, body)
			.then((data) => setAnnotations(previous => previous.map(a => (a.id === annotation.id ? Annotation.fromConfig(Object.assign(modifiedAnnotation, data)) : a))))
			.catch(err => alert("Unhandled Exception: Modifying annotation unsuccessful. " + err))
	}

	async function deleteAnnotations(annotationIds: number[]) {
		const body = {
			annotation_ids: annotationIds,
		}

		return endpointProvider
			.post(LINKS.DATA.PROFILING.DELETE_ANNOTATIONS, body)
			.then(() => getAnnotationGroups())
			.then(groups => {
				setAnnotations(previous => previous.filter(annotation => !annotationIds.includes(annotation.id)))
				setAnnotationGroups(groups)
			})
	}

	async function getAnnotationGroups() {
		return endpointProvider.post(LINKS.DATA.PROFILING.GET_ANNOTATION_GROUPS, {
			data_object_id: patientInfo.dataSourceMap.get(DataSource.CURRENT_PATIENT)
		})
	}

	async function deleteGroupAnnotations(annotationGroup: AnnotationGroup) {
		return endpointProvider
			.post(LINKS.DATA.PROFILING.GET_ANNOTATIONS, {
				annotation_group_ids: [annotationGroup.id],
			})
			.then(annotations => annotations.flatMap((annotation: any) => annotation.id))
			.then(annotationIds => deleteAnnotations(annotationIds))
			.then(() => getAnnotationGroups())
			.then(groups => {
				setAnnotations(previous => [...previous].filter(annotation => annotation.group_id !== annotationGroup.id))
				setAnnotationGroups(groups)
			})
	}

	async function deleteAnnotationGroup(annotationGroup: AnnotationGroup) {
		return deleteGroupAnnotations(annotationGroup)
			.then(() =>
				endpointProvider.post(LINKS.DATA.PROFILING.DELETE_ANNOTATION_GROUP, {
					annotation_group_id: annotationGroup.id,
				})
			)
			.then(() => setAnnotationGroups(previous => [...previous].filter(group => group.id !== annotationGroup.id)))
	}

	async function deleteAnnotationGroups(annotationGroups: AnnotationGroup[]) {
		return endpointProvider
			.post(LINKS.DATA.PROFILING.DELETE_ANNOTATION_GROUPS, {
				annotation_group_ids: annotationGroups.map(group => group.id),
			})
			.then(() => setAnnotationGroups(previous => [...previous].filter(group => !annotationGroups.includes(group))))
	}

	async function loadAnnotationGroups(groupIds: string[]) {
		return endpointProvider
			.post(LINKS.DATA.PROFILING.GET_ANNOTATIONS, {
				annotation_group_ids: groupIds.map(id => parseInt(id)),
			})
			.then((response: object) => {
				const annotations: Annotation[] = Object.values(response)
					.flat()
					.flatMap((json: AnnotationJSON) => {
						const annotation = Annotation.fromJSON(json)
						annotation.loaded = true
						return annotation
					})

				loadAnnotations(annotations)
			})
	}

	function unloadAnnotationGroups(groupIds: string[]) {
		setAnnotations(previous => [...previous].filter(annotation => !groupIds.includes(annotation.group_id)))
	}

	async function createAnnotationGroup(groupName: string, dataObjectId: number) {
		return endpointProvider
			.post(LINKS.DATA.PROFILING.CREATE_ANNOTATION_GROUP, {
				data_object_id: dataObjectId,
				annotation_group_name: groupName,
				user_id: authProvider.currentUser.id,
			})
			.then((json: string) => {
				const data: { id: string; timestamp: number } = JSON.parse(json)
				const newAnnotationGroup: AnnotationGroup = { id: data.id, timestamp: new Date(data.timestamp), group_name: groupName, nannotations: 0 }
				setAnnotationGroups(previous => [...previous, newAnnotationGroup])
				return newAnnotationGroup
			})
			.catch(error => {
				alert(error)
				return undefined
			})
	}

	async function renameAnnotationGroup(annotationGroup: AnnotationGroup, newName: string) {
		return endpointProvider
			.post(LINKS.DATA.PROFILING.RENAME_ANNOTATION_GROUP, {
				annotation_group_id: annotationGroup.id,
				new_group_name: newName,
			})
			.then(() => setAnnotationGroups(previous => previous.map(group => (group.id === annotationGroup.id ? { ...group, group_name: newName } : group))))
			.catch(error => alert("Renaming annotation group unsuccessful: " + error))
	}

	// There's no good reason why this needs to be here. We can just check to see if the annotation is rendered.
	function withLoadedState(annotation: Annotation, loadedState: boolean) {
		const newAnnotation = new Annotation()
		Object.assign(newAnnotation, annotation)
		newAnnotation.loaded = loadedState
		return newAnnotation
	}

	function loadAnnotations(annotations: Annotation[]) {
		setAnnotations(previous => {
			const existingAnnotationIds = previous.map(annotation => annotation.id)
			const newLoadedAnnotations = annotations.filter(annotation => !existingAnnotationIds.includes(annotation.id))
			return [...previous, ...newLoadedAnnotations].map(annotation => withLoadedState(annotation, true))
		})
	}

	function unloadAnnotations(annotations: Annotation[]) {
		const annotationIds = annotations.map(annotation => annotation.id)
		setAnnotations(previous => [...previous].filter(annotation => !annotationIds.includes(annotation.id)))
	}

	const getAnnotationsForGroups = useCallback(
		(annotationGroupIds: string[]): Promise<Annotation[]> => {
			const body = {
				annotation_group_ids: annotationGroupIds,
			}

			return endpointProvider.post(LINKS.DATA.PROFILING.GET_ANNOTATIONS, body).then((response: object) => {

				const annotations = Object.values(response)
					.flat()
					.flatMap((json: AnnotationJSON) => Annotation.fromJSON(json))

				return annotations
		})
		},
		[LINKS.DATA.PROFILING.GET_ANNOTATIONS, endpointProvider]
	)

	return {
		createAnnotation,
		createAnnotationGroup,
		deleteAnnotationGroup,
		deleteAnnotationGroups,
		loadAnnotations,
		unloadAnnotations,
		loadAnnotationGroups,
		unloadAnnotationGroups,
		modifyAnnotation,
		deleteAnnotations,
		getAnnotationsForGroups,
		getAnnotationGroups,
		renameAnnotationGroup
	}
}
