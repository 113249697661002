
import { Empty } from 'antd';
import React, { useEffect, useState, createContext } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ModalFooter, Scrollbar } from "../../../../Constants/StyledComponents";
import { useLayoutService } from "../../../../Hooks/useLayoutService";
import { useOnMount } from "../../../../Hooks/useOnMount";
import { editedLayoutGroupAtom, madeChangesToEditedLayoutGroupAtom, uneditedLayoutGroupAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Layout";
import { useModalProvider } from "../../../../Providers/ModalProvider";
import { modalityUpdates, useModalitiesProvider } from "../../../../Providers/ModalitiesProvider";
import { modalityGraphGroupConfigAtom } from '../../../../Pages/Data/Visualize/DataReview/Atoms/ModalityGraphGroup';
import { MobergButton, MobergButtonShape, MobergButtonVariant, MobergFontSize, MobergTheme, MobergHeader, MobergShortTextInput, MobergDropdown, MobergColumn, MobergRow, MobergText, getColors } from '../../../../Moberg';
import ConfirmationModal from '../../../../Components/ConfirmationModal/ConfirmationModal';
import { DataSource } from '../../../../Pages/Data/Visualize/DataReview/Types/DataSource'
import { GraphToggleButton } from "./GraphToggleButton"
import { RenderStrategy } from '../../../../Pages/Data/Visualize/DataReview/Types/Trace';
import { LineGraphModalities } from "./LineGraphModalities"
import { HeatmapGraphModalities } from "./HeatmapGraphModalities"
import { ColorSpectrum } from '../../../../Pages/Data/Visualize/DataReview/Types/ColorSpectrum';
import { ConfigureWindowModalHeader } from "./ConfigureWindowModalHeader"
import { currentPatientFileInfoAtom } from '../../../../Pages/Data/Visualize/DataReview/Atoms/PatientFile';
import { useEndpointProvider } from '../../../../Providers/EndpointProvider';
import { useBackendLinksProvider } from '../../../../Providers/BackendLinksProvider';
import { Analytic } from '../../../../Pages/Data/Visualize/DataReview/Types/AnalysisDetails';

export const ConfigureWindowModalContext = createContext({})

export function ConfigureWindowModal({ layoutId, windowId }) {
    const { LINKS } = useBackendLinksProvider()
    const { post } = useEndpointProvider()
    const { createModal, close } = useModalProvider()
    const { modifyLayoutGroup } = useLayoutService()
    const setUneditedLayoutGroup = useSetRecoilState(uneditedLayoutGroupAtom)
    const setTimeSeriesGraphGroup = useSetRecoilState(modalityGraphGroupConfigAtom({ windowId, layoutId }))
    const [{ patientId, patientModalities }, setPatientFileInfo] = useRecoilState(currentPatientFileInfoAtom)

    const modalitiesProvider = useModalitiesProvider()
    const madeChanges = useRecoilValue(madeChangesToEditedLayoutGroupAtom)

    const [editedLayoutGroup, setEditedLayoutGroup] = useRecoilState(editedLayoutGroupAtom)
    const [selectedGraphId, setSelectedGraphId] = useState()
    const [waitingForNetwork, setWaitingForNetwork] = useState(false)
    const [error, setError] = useState() // network error. useAsyncTask handles this better than defining the state here.
    const [validationErrors, setValidationErrors] = useState(new Map())
    const validationErrorsArray = Array.from(validationErrors.values())
    const red = getColors(MobergTheme.RED).main

    const currentLayout = editedLayoutGroup?.layouts.find(layout => layout.id === layoutId)
    const currentWindow = currentLayout?.areas.find(({ area }) => area === windowId)
    const currentGraph = !selectedGraphId && (currentWindow?.props?.graphs ?? []).length > 0
        ? currentWindow.props.graphs[0]
        : currentWindow?.props.graphs.find(graph => selectedGraphId && graph.id === selectedGraphId)

    const sortedModalities = [...modalitiesProvider.modalities].sort();

    const traceOptions = sortedModalities.filter(modality => !modality.startsWith('EEG')).map(name => ({
        label: name,
        value: {
            name,
            dataKey: name,
            dataSource: DataSource.CURRENT_PATIENT,
        },
        highlight: patientModalities.includes(name)
    }))

    // TODO: Remove this when the Run Analysis changes have merged (CONN-5076)
    const defaultPRxConfig = {
        analytic: Analytic.PRx,
        calculationPeriodMs: 1000 * 60, // 1 minute
        calculationWindowMs: 1000 * 60 * 5, // 5 minutes
        ABP: "ABP_na",
        ICP: "ICP_na"
    }
    
    traceOptions.push({
        label: "PRx",
        value: {
            name: "PRx",
            dataKey: JSON.stringify(defaultPRxConfig),
            dataSource: DataSource.CURRENT_PATIENT,
            onDemandAnalysis: defaultPRxConfig
        }
    })

    useOnMount(() => {
        modalitiesProvider.update(modalityUpdates.MODALITIES)
        post(LINKS.DATA.TRIALS.GET_MODALITIES_INTERSECTION.LINK, { patient_uids: [patientId] }).then(patientModalities => {
            setPatientFileInfo(previous => ({ ...previous, patientModalities }))
        })
    })

    useEffect(() => {
        if (!selectedGraphId && currentWindow?.props.graphs.length > 0) {
            setSelectedGraphId(currentWindow.props.graphs[0].id)
        }

    }, [currentWindow.props.graphs, selectedGraphId])

    const toggleCurrentGraph = (graph) => {
        if (selectedGraphId === graph.id) {
            return
        }

        setSelectedGraphId(graph.id)
    }

    function addGraph() {

        const newGraph = {
            id: `graph-${new Date(Date.now()).toISOString()}`,
            name: 'New graph',
            traces: [],
            renderStrategy: RenderStrategy.LINE,
            min: 0,
            max: 100
        }

        // This is so complex, it probably should be being done by a reducer.
        setEditedLayoutGroup(previous => {
            return {
                ...previous,
                layouts: previous.layouts.map(layout => {
                    if (layout.id === layoutId) {
                        return {
                            ...layout,
                            areas: layout.areas.map(area => {
                                if (area.area === windowId) {
                                    return {
                                        ...area,
                                        props: {
                                            ...area.props,
                                            graphs: [...area.props.graphs ?? [], newGraph]
                                        }
                                    }
                                }
                                return area
                            })
                        }
                    }
                    return layout
                })
            }
        })
    }

    function removeGraph(graph) {
        // This is so complex, it probably should be being done by a reducer.
        setEditedLayoutGroup(previous => {
            return {
                ...previous,
                layouts: previous.layouts.map(layout => {
                    if (layout.id === layoutId) {
                        return {
                            ...layout,
                            areas: layout.areas.map(area => {
                                if (area.area === windowId) {
                                    return {
                                        ...area,
                                        props: {
                                            ...area.props,
                                            graphs: [...area.props.graphs?.filter(g => g !== graph)] ?? []
                                        }
                                    }
                                }
                                return area
                            })
                        }
                    }
                    return layout
                })
            }
        })
    }

    const modalBodyGraph = (
        <MobergColumn style={{ padding: "16px" }}>
            <MobergColumn gap="8px">
                <MobergHeader>
                    Graphs
                </MobergHeader>

                <MobergColumn gap="8px">
                    <div>
                        {currentWindow?.props.graphs.length > 0
                            ? currentWindow.props.graphs.map(graph => (
                                <GraphToggleButton
                                    graph={graph}
                                    isActive={graph.id === currentGraph?.id}
                                    removeGraph={removeGraph}
                                    toggleCurrentGraph={toggleCurrentGraph}
                                />))
                            : <Empty description={<p style={{ fontFamily: 'Source Sans Pro', fontSize: '26px' }}>No Graphs</p>} />
                        }
                    </div>

                    <MobergButton
                        onClick={addGraph}
                        shape={MobergButtonShape.FULL_WIDTH}
                        theme={MobergTheme.BLUE}
                        variant={MobergButtonVariant.OUTLINE}
                    >
                        Add graph
                    </MobergButton>

                </MobergColumn>
            </MobergColumn>
        </MobergColumn>
    )

    function saveChanges() {
        setWaitingForNetwork(true)
        modifyLayoutGroup(editedLayoutGroup.id, editedLayoutGroup)
            .then(close)
            .catch(err => {
                setWaitingForNetwork(false)
                setError(err)
            })
        setUneditedLayoutGroup(editedLayoutGroup)
        setTimeSeriesGraphGroup(previous => ({ ...previous, ...currentWindow.props }))
    }

    function closeConfigurationModal() {
        if (madeChanges) {
            createModal(
                <ConfirmationModal
                    title={"Discard Changes?"}
                    description={"You have unsaved changes. Are you sure you want to close without saving?"}
                    confirmButton={{ text: "Close without saving", theme: MobergTheme.RED, onClick: close }}
                />
            )
        } else {
            close()
        }
    }

    function updateGraphProperty(property, valueOrSetter) {
        setEditedLayoutGroup(previous => {
            const newLayoutGroup = {
                ...previous,
                layouts: previous.layouts.map(layout => {
                    if (layout.id === layoutId) {
                        return {
                            ...layout,
                            areas: layout.areas.map(window => {
                                if (window.area === windowId) {
                                    return {
                                        ...window,
                                        props: {
                                            ...window.props,
                                            graphs: window.props.graphs.map(graph => {
                                                if (graph.id === currentGraph?.id) {
                                                    let newValue

                                                    if (valueOrSetter instanceof Function) {
                                                        newValue = valueOrSetter(graph[property])
                                                    } else {
                                                        newValue = valueOrSetter
                                                    }

                                                    return {
                                                        ...graph,
                                                        [property]: newValue
                                                    }
                                                }
                                                return graph
                                            })
                                        }
                                    }
                                }
                                return window
                            })
                        }
                    }
                    return layout
                })
            }

            return newLayoutGroup
        })
    }

    function updateDisplayPageSize(newValue) {
        setEditedLayoutGroup(previous => {
            const newLayoutGroup = {
                ...previous,
                layouts: previous.layouts.map(layout => {
                    if (layout.id === layoutId) {
                        return {
                            ...layout,
                            areas: layout.areas.map(window => {
                                if (window.area === windowId) {
                                    return {
                                        ...window,
                                        props: {
                                            ...window.props,
                                            viewDuration: newValue
                                        }
                                    }
                                }
                                return window
                            })
                        }
                    }
                    return layout
                })
            }

            return newLayoutGroup
        })
    }

    function removeModalityAt(index) {
        const newTraces = [...(currentGraph?.traces ?? [])]
        newTraces.splice(index, 1) // be careful. Splice returns the elements that were removed.

        // This technically has the potential for bugs. It should be relatively safe, but watch out for that.
        updateGraphProperty("traces", newTraces)
    }

    const handleGraphTypeChanged = (renderStrategy) => {
        updateGraphProperty("renderStrategy", renderStrategy)

        let defaultProps

        switch (renderStrategy) {
            case RenderStrategy.LINE:
                defaultProps = { color: "#000" }
                break

            case RenderStrategy.HEATMAP:
                defaultProps = {
                    colorSpectrum: ColorSpectrum.INFERNO,
                    lowerBound: 0,
                    upperBound: 100
                }
                break
            default:
                throw new Error("unexpected graph type: " + renderStrategy)
        }

        updateGraphProperty("traces", previous => previous.map(trace => ({ ...trace, ...defaultProps, renderStrategy })))
        setValidationErrors(new Map())
    }

    const getModalitiesSection = () => {
        switch (currentGraph?.renderStrategy) {
            case RenderStrategy.HEATMAP:
                return <HeatmapGraphModalities />
            case RenderStrategy.LINE:
            default:
                return <LineGraphModalities />
        }
    }

    return (
        <ConfigureWindowModalContext.Provider value={{ traceOptions, currentGraph, currentWindow, closeModal: closeConfigurationModal, updateDisplayPageSize, updateGraphProperty, removeModalityAt, setValidationErrors }}>
            <MobergColumn style={{ minWidth: "1150px" }}>

                <ConfigureWindowModalHeader />

			    <hr style={{ height: '0px', border: '1px solid #B3B3B3', margin: 0 }} />

                <MobergColumn style={{ flex: 1 }}>
                    <MobergRow style={{ flex: 1 }} verticalAlign='start'>
                        {modalBodyGraph}

                        <MobergColumn style={{ flex: 1 }}>
                            <MobergRow horizontalAlign={currentGraph ? "start" : "center"} style={{ height: "60vh" }}>
                                {!currentGraph
                                    ? (<MobergColumn verticalAlign="center" expand={true}>
                                        <MobergRow horizontalAlign="center" expand={true}>
                                            <MobergText fontSize={MobergFontSize.LARGE} style={{ padding: "16px" }}>
                                                Select a graph to customize
                                            </MobergText>
                                        </MobergRow>
                                    </MobergColumn>
                                    )
                                    : (
                                        <MobergColumn expand={true} style={{ width: "100%", background: '#FFFFFF', borderLeft: '2px solid #DDDDDD' }}>
                                            <Scrollbar>
                                                <MobergColumn gap="48px" style={{ padding: "16px", flex: 1 }}>
                                                    <MobergColumn gap="16px">
                                                        <MobergHeader>
                                                            Graph
                                                        </MobergHeader>

                                                        <MobergColumn gap="8px" style={{ padding: "0px 16px" }}>
                                                            <MobergShortTextInput
                                                                orientation="row"
                                                                label={"Title"}
                                                                limit={25}
                                                                defaultValue={currentGraph?.name}
                                                                value={currentGraph?.name}
                                                                onChange={newName => updateGraphProperty("name", newName)}
                                                            />

                                                            <MobergDropdown
                                                                label="Type"
                                                                selectedValue={currentGraph?.renderStrategy}
                                                                options={[
                                                                    { label: "Line", value: RenderStrategy.LINE },
                                                                    { label: "Heatmap", value: RenderStrategy.HEATMAP }
                                                                ]}
                                                                onChange={handleGraphTypeChanged}
                                                            />
                                                        </MobergColumn>
                                                    </MobergColumn>

                                                    <MobergColumn gap="16px">
                                                        <MobergHeader>
                                                            Modalities
                                                        </MobergHeader>

                                                        {getModalitiesSection()}

                                                    </MobergColumn>

                                                    <MobergColumn gap="16px">

                                                    </MobergColumn>
                                                </MobergColumn>
                                            </Scrollbar>
                                        </MobergColumn>
                                    )
                                }
                            </MobergRow>
                        </MobergColumn>
                    </MobergRow>
                </MobergColumn>

                <hr style={{ height: '0px', border: '1px solid #B3B3B3', margin: 0 }} />
                <ModalFooter>
                    <MobergRow gap="16px" horizontalAlign="right">
                        {waitingForNetwork && (
                            <div style={{ display: "flex", alignItems: "center" }}> Please wait... </div>
                        )}

                        {!waitingForNetwork && error && (
                            <div style={{ display: "flex", alignItems: "center", color: red }}> {error} </div>
                        )}

                        {validationErrorsArray.length > 0 && (
                            <MobergText style={{ color: red }}>
                                {validationErrorsArray[0]}
                            </MobergText>
                        )}

                        <MobergButton
                            disabled={!madeChanges || waitingForNetwork || Array.from(validationErrors).length > 0}
                            onClick={saveChanges}
                            theme={MobergTheme.BLUE}
                            variant={MobergButtonVariant.FILLED}
                            fontSize={MobergFontSize.LARGE}
                            shape={MobergButtonShape.WIDE}
                        >
                            Save
                        </MobergButton>
                    </MobergRow>
                </ModalFooter>
            </MobergColumn>
        </ConfigureWindowModalContext.Provider>
    )
}

export default ConfigureWindowModal;
