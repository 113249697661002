import React, { useContext, useEffect, useState } from "react"
import { MdClose, MdSettings } from "react-icons/md"
import {
	MobergButton,
	MobergButtonShape,
	MobergButtonVariant,
	MobergDropdown,
	MobergIconSize,
	MobergInputLabel,
	MobergNumberInput,
	MobergRow,
	MobergTheme,
	getColors,
} from "../../../../Moberg"
import { ColorSpectrum } from "../../../../Pages/Data/Visualize/DataReview/Types/ColorSpectrum"
import { CompositePartTraceConfigJSON, HeatmapTraceConfigJSON, RenderStrategy, TraceConfigJSON, getHeatmapTraceId } from "../../../../Pages/Data/Visualize/DataReview/Types/Trace"
import { ConfigureWindowModalContext } from "./ConfigureWindowModal"
import { SpectrumSample } from "./SpectrumSample"
import { EditAnalysisModal } from "../../../../Pages/Data/Visualize/DataReview/Components/Modals/EditAnalysis/EditAnalysisModal"
import { useModalProvider } from "../../../../Providers/ModalProvider"
import { OnDemandAnalysis } from "../../../../Pages/Data/Visualize/DataReview/Types/AnalysisDetails"

export const HeatmapGraphModalities: React.FC = () => {
	const { traceOptions, currentGraph, updateGraphProperty, removeModalityAt, setValidationErrors } = useContext(ConfigureWindowModalContext)
	const [duplicateHeatmap, setDuplicateHeatmap] = useState<HeatmapTraceConfigJSON | undefined>()
	const red = getColors(MobergTheme.RED).main
	const { createModal } = useModalProvider()

	const addModality = () =>
		updateGraphProperty("traces", (previous: TraceConfigJSON[]) => {
			const newTrace: HeatmapTraceConfigJSON = {
				id: `trace-${new Date(Date.now()).toISOString()}`,
				...traceOptions[0].value,
				renderStrategy: RenderStrategy.HEATMAP,
				lowerBound: 0,
				upperBound: 100,
				colorSpectrum: ColorSpectrum.INFERNO,
			}

			return [...previous, newTrace]
		})

	const updateTraceAt = (index: number, config: Partial<HeatmapTraceConfigJSON>) => {
		const newTraces: TraceConfigJSON[] = [...(currentGraph?.traces ?? [])]

		const newTrace = {
			...newTraces[index],
			...config,
		} as HeatmapTraceConfigJSON

		if (config.isCompositePart && config.compositeIndex !== undefined) {
			(newTrace as CompositePartTraceConfigJSON).compositeIndex = config.compositeIndex
		}

		newTraces[index] = newTrace

		updateGraphProperty("traces", newTraces)
	}

	const handleValidationErrorChange = (key: string, error: string | undefined) => {
		setValidationErrors(previous => {
			const copy = new Map(previous)

			if (copy.has(key) && error === undefined) {
				copy.delete(key)
			} else if (error !== undefined) {
				copy.set(key, error)
			}

			return copy
		})
	}

	const handleColorSpectrumChange = (colorSpectrum: ColorSpectrum, index: number) => {
		updateTraceAt(index, { colorSpectrum })
	}

	const handleDelete = (index: number, configId: string) => {
		removeModalityAt(index)

		// When we delete the component, we have to remove the validation errors that belonged to these components.
		handleValidationErrorChange(`${configId}-lowerBound`, undefined)
		handleValidationErrorChange(`${configId}-upperBound`, undefined)
	}

	const handleTraceAnalysisChanged = (index: number, analysis: OnDemandAnalysis) => {
		updateTraceAt(index, { 
			onDemandAnalysis: analysis,
			dataKey: JSON.stringify(analysis)
		})
	}

	useEffect(() => {
        const uniqueHeatmaps = new Set()
		let duplicate: HeatmapTraceConfigJSON | undefined

		for (const trace of (currentGraph?.traces ?? [])) {
			const id = getHeatmapTraceId(trace as HeatmapTraceConfigJSON)

			if (uniqueHeatmaps.has(id)) {
				duplicate = trace as HeatmapTraceConfigJSON
				break
			}

            uniqueHeatmaps.add(id)
		}

		const validationError = duplicate !== undefined
			? `${duplicate.name} has a duplicate heatmap. Either change the settings, or remove the duplicate.`
			: undefined

		setDuplicateHeatmap(duplicate)

		setValidationErrors(previous => {
            const copy = new Map(previous)

            if (validationError !== undefined) {
                copy.set("duplicateHeatmap", validationError)
            } else {
                copy.delete("duplicateHeatmap")
            }

            return copy
        })
    }, [currentGraph?.renderStrategy, currentGraph?.traces, setValidationErrors])

	return (
		<div style={{ display: "grid", gridTemplateColumns: "auto auto auto auto auto auto auto", columnGap: "16px", rowGap: "8px", padding: "0 16px" }}>

			<MobergInputLabel text={"Name"} />
			<MobergInputLabel text={"Color spectrum"} />
			<MobergInputLabel text={"Lower Bound"} />
			<MobergInputLabel text={""} />
			<MobergInputLabel text={"Upper Bound"} />
			<MobergInputLabel text={"Calculation"} />
			<MobergInputLabel text={""} />

			{(currentGraph?.traces ?? []).map((trace, index: number) => {
				const heatmapTrace: HeatmapTraceConfigJSON = trace as HeatmapTraceConfigJSON
				const { id } = heatmapTrace

				return (
					<>
						<MobergDropdown
							key={`${id}-modalitySelector`}
							options={traceOptions}
							onChange={trace => updateTraceAt(index, trace)}
							selectedValue={trace}
							equals={(a, b) => a.name === b.name && a.dataSource === b.dataSource}
							width={175}
						/>

						<MobergDropdown
							key={`${id}-spectrumSelector`}
							selectedValue={heatmapTrace.colorSpectrum}
							onChange={spectrum => handleColorSpectrumChange(spectrum, index)}
							options={[
								{ label: "Inferno", value: ColorSpectrum.INFERNO },
								{ label: "Viridis", value: ColorSpectrum.VIRIDIS },
								{ label: "Grays", value: ColorSpectrum.GRAYS },
								{ label: "Greens", value: ColorSpectrum.GREENS },
								{ label: "Reds", value: ColorSpectrum.REDS },
								{ label: "Red-Blue", value: ColorSpectrum.RED_BLUE },
							]}
							width={175}
						/>

						<MobergNumberInput 
							key={`${id}-lowerBound`}
							defaultValue={heatmapTrace.lowerBound} 
							onChange={lowerBound => updateTraceAt(index, { lowerBound })}
							onValidationErrorChange={error => handleValidationErrorChange(`${id}-lowerBound`, error)}
						/>

						<MobergRow key={`${id}-spectrumSample`}>
							<SpectrumSample colorSpectrum={heatmapTrace.colorSpectrum} />
						</MobergRow>

						<MobergNumberInput
							key={`${id}-upperBound`}
							defaultValue={heatmapTrace.upperBound}
							onChange={upperBound => updateTraceAt(index, { upperBound })}
							onValidationErrorChange={error => handleValidationErrorChange(`${id}-upperBound`, error)}
						/>

						<MobergButton
							disabled={trace.onDemandAnalysis === undefined}
							tooltip={trace.onDemandAnalysis 
								? "Edit the calculation of " + trace.onDemandAnalysis.analytic 
								: "This modality is a raw signal."}
							shape={MobergButtonShape.SQUARE}
							onClick={() => {
								if (trace.onDemandAnalysis?.analytic) {
									createModal(<EditAnalysisModal 
										analysis={trace.onDemandAnalysis}
										onChange={(newArgs: OnDemandAnalysis) => handleTraceAnalysisChanged(index, newArgs)}
									/>)
								}
							}}
						>
							<MdSettings size={MobergIconSize.REGULAR} />
						</MobergButton>

						<MobergButton
							key={`${id}-deleteHeatmap`}
							shape={MobergButtonShape.SQUARE}
							onClick={() => handleDelete(index, id)}
							style={{ padding: "6px", 
								outline: duplicateHeatmap && getHeatmapTraceId(heatmapTrace) === getHeatmapTraceId(duplicateHeatmap) 
									? `2px solid ${red}` 
									: "none" }}
							>
							<MdClose size={MobergIconSize.REGULAR} />
						</MobergButton>
					</>
				)
			})}

			<MobergButton onClick={addModality} theme={MobergTheme.BLUE} variant={MobergButtonVariant.OUTLINE} style={{ width: "175px" }}>
				Add modality
			</MobergButton>

		</div>
	)
}
