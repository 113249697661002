import { D3DragEvent, DragBehavior, EnterElement, ScaleLinear, Selection, axisBottom, drag } from "d3";
import { ScatterPlotReactCallbacks } from "../../../../Types/ReactCallbacks";
import { D3OneToOneRenderable } from "../../../D3/D3OneToOneRenderable";
import { MarginedBoundingBox } from "../../../../Types/MarginedBoundingBox";
import { D3DragOverlay } from "../../../D3/D3DragOverlay";
import { AxisConfig } from "../../../../Types/ScatterPlot";

export type ScatterPlotXAxisConfig = {
    boundingBox: MarginedBoundingBox
    scale: ScaleLinear<any, any, any>
    stepSize: number
    xAxisConfig: AxisConfig
    onDrag: () => void
    onDoubleClick: () => void
}

export class D3ScatterPlotXAxis extends D3OneToOneRenderable<SVGGElement, SVGGElement, ScatterPlotXAxisConfig, ScatterPlotReactCallbacks> {
    private axisClassName: string = "d3-scatter-plot-x-axis"
    private dragOverlay?: D3DragOverlay
    private dragOverlayHeight: number = 30
    private dragOverlayHorizontalExtension: number = 0
    private dragBehavior: DragBehavior<any, any, any>

    constructor(root: SVGGElement, config: ScatterPlotXAxisConfig, reactCallbacks: ScatterPlotReactCallbacks) {
        super(root, config, "d3-scatter-plot-x-axis-container", reactCallbacks)

        this.dragBehavior = this.dragBehavior = drag()
            .on("drag", this.onDrag)
            .on("end", this.onDragEnd)

        this.render()
    }

    protected enter(newElements: Selection<EnterElement, ScatterPlotXAxisConfig, any, any>): Selection<SVGGElement, ScatterPlotXAxisConfig, SVGGElement, any> {
        // Create the Axis Group
        const axisGroup = newElements
            .append("g")
            .attr("class", this.className)
            .attr("transform", `translate(0, ${this.config.boundingBox.height})`)

        axisGroup.append("g")
            .attr("class", this.axisClassName)
            .style("user-select", "none") // disables highlighting the ticks

        axisGroup.each((config, i, nodes) => this.createChildren(config, i, nodes))

        return axisGroup
    }

    protected update(updatedElements: Selection<SVGGElement, ScatterPlotXAxisConfig, any, any>): Selection<SVGGElement, ScatterPlotXAxisConfig, SVGGElement, any> {
        const axisGroup = updatedElements.attr("transform", `translate(0, ${this.config.boundingBox.height})`)
        const d3Axis = axisGroup.select("." + this.axisClassName) as Selection<SVGGElement, any, any, any>

        d3Axis.call(axisBottom(this.config.scale))

        return updatedElements
    }

    protected updateDerivedState(): void {
        this.updateChildren(this.config)
    }

    protected createChildren(config: ScatterPlotXAxisConfig, index: number, nodes: ArrayLike<SVGGElement>): void {
        const root = nodes[index]
        const width = config.scale.range()[1]
        const boundingBox = {
            x: 0,
            y: 0,
            width: width + 2 * this.dragOverlayHorizontalExtension,
            height: this.dragOverlayHeight,
        }
        this.dragOverlay = new D3DragOverlay(root, { boundingBox, dragBehavior: this.dragBehavior }, this.reactCallbacks, { onDoubleClick: this.config.onDoubleClick })
    }

    protected updateChildren = (config: ScatterPlotXAxisConfig) => {
        const width = config.scale.range()[1]
        const boundingBox = {
            x: 0,
            y: 0,
            width: width + 2 * this.dragOverlayHorizontalExtension,
            height: this.dragOverlayHeight,
        }

        this.dragOverlay?.updateConfig({ boundingBox, dragBehavior: this.dragBehavior })
    }

    protected renderChildren = () => {
        this.dragOverlay?.render()
    }

    private onDrag = (dragEvent: D3DragEvent<SVGRectElement, ScatterPlotXAxisConfig, any>) => {
        const min = this.config.scale.domain()[0]
        const max = this.config.scale.domain()[1]
        const width = this.config.scale.range()[1] - this.config.scale.range()[0]

        // Adjust sensitivity for zooming and panning
        const range = max - min
        const zoomSensitivity = range / width * 2; // Increased zoom sensitivity
        const panSensitivity = range / width
        let { dx, dy } = dragEvent

        // Panning: Adjust domain based on dx, simple left and right movement
        const newMinX = min - dx * panSensitivity
        const newMaxX = max - dx * panSensitivity

        // Zooming: Apply zoom based on dy, increase or decrease the scale
        // The zoom effect is centered around the middle of the current view
        const midPoint = (newMinX + newMaxX) / 2
        const zoomFactor = 1 - dy * zoomSensitivity / range; // Adjust this factor to control zoom speed
        const zoomedMinX = midPoint - (midPoint - newMinX) * zoomFactor
        const zoomedMaxX = midPoint + (newMaxX - midPoint) * zoomFactor

        // Update the scale domain with either panned or zoomed values
        this.config.scale.domain([zoomedMinX, zoomedMaxX])

        requestAnimationFrame(() => {
            if (this.config.onDrag) {
                this.config.onDrag()
            }
            this.render()
        })
    }

    private onDragEnd = () => {
        const min = this.config.scale.domain()[0]
        const max = this.config.scale.domain()[1]

        this.reactCallbacks.setRootConfig(previous => {
            const xAxisConfig = { ...previous.xAxisConfig }
            xAxisConfig.maxValue = max
            xAxisConfig.minValue = min
            return { ...previous, xAxisConfig: xAxisConfig }
        })
    }
}